<template>
<div>
  <template v-if="streetWidgetForm.list.length > 0">
    <field-list :title="'街道扩展字段'" :data="streetWidgetForm" :value="streetWidgetModels" :disabled="disabled" ref="streetWidgetForm"/>
    </template>
  <template v-if="communityWidgetForm.list.length > 0">
    <field-list :title="'社区扩展字段'" :data="communityWidgetForm" :value="communityWidgetModels" :disabled="disabled" ref="communityWidgetForm"/>
  </template>
</div>
</template>

<script>
import fieldList from './field-list.vue'

export default {
  components: { fieldList },
  props: ['disabled'],
  data () {
    return {
      streetWidgetForm: {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: 'right',
          size: 'small'
        },
      },
      streetWidgetModels: {},
      communityWidgetForm : {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: 'right',
          size: 'small'
        },
      },
      communityWidgetModels: {},
      remoteFuncs: {},
    }
  },
  methods: {
    init (sqlId, type, orgId) {
      this.reset()
      this.formData = {
        sqlId, type, orgId
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/extend/config/info'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: orgId,
          type: type,
          only: 0
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.extend['4']) {
            this.streetWidgetForm = data.extend['4']
            this.formData['streetPageId'] = data.extend['4'].pageId
          }
          if (data.extend['5']) {
            this.communityWidgetForm = data.extend['5']
            this.formData['communityPageId'] = data.extend['5'].pageId
          }
          this.getFormValue(sqlId, type)
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    getFormValue (sqlId, type) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/extend/info/getExtend'),
        method: 'post',
        params: this.$http.adornParams({
          sqlId: sqlId,
          type: type
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let sArr = []
          let cArr = []
          data.info.forEach(item => {
            if (item.pageId == this.formData['streetPageId']) {
              this.formData['streetUpdateId'] = item.id || null
              sArr = item.map || null
            }
            if (item.pageId == this.formData['communityPageId']) {
              this.formData['communityUpdateId'] = item.id || null
              cArr = item.map || null
            }
          });
          this.streetWidgetModels = sArr
          this.communityWidgetModels = cArr
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    async submitForm () {
      let obj = {
        street: {},
        community: {}
      }
      let arr = []
      if (this.streetWidgetForm.list.length > 0) {
        obj.street['map'] = await this.$refs.streetWidgetForm.getData()
        obj.street['sqlId'] = this.formData.sqlId || null
        obj.street['orgId'] = this.formData.orgId || null
        obj.street['type'] = this.formData.type || null
        obj.street['id'] = this.formData.streetUpdateId || null
        obj.street['pageId'] = this.formData.streetPageId || null
        arr.push(obj.street)
      }
      if (this.communityWidgetForm.list.length > 0) {
        obj.community['map'] = await this.$refs.communityWidgetForm.getData()
        obj.community['orgId'] = this.formData.orgId || null
        obj.community['sqlId'] = this.formData.sqlId || null
        obj.community['type'] = this.formData.type || null
        obj.community['id'] = this.formData.communityUpdateId || null
        obj.community['pageId'] = this.formData.communityPageId || null
        arr.push(obj.community)
      }
      return arr
    },
    reset () {
      this.StreetWidgetForm = {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: 'right',
          size: 'small'
        },
      },
      this.streetWidgetModels = {}
      this.communityWidgetForm = {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: 'right',
          size: 'small'
        },
      }
      this.communityWidgetModels = {}
      this.remoteFuncs = {}
    }
  }
}
</script>

<style>

</style>