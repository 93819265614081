<template>
  <van-cell-group class="info-second">
    <van-cell class="info-title">
      <template #title>
        <div class="blue-box"></div>
        <span class="custom-title">{{title}}</span>
      </template>
    </van-cell>
    <van-form @submit="submit" ref="vanForm">
      <div  v-for="(item, index) in models" :key="item.key">
        <field-list-item :datas="item" :value="valueList[index]" :disabled="disabled" ref="fieldListItem"/>
      </div>
    </van-form>
  </van-cell-group>
</template>

<script>
import fieldListItem from './field-list-item.vue'
export default {
  components: { fieldListItem },
  props: ['title', 'data', 'value', 'disabled'],
  data () {
    return {
      models: [],
      valueList: []
    }
  },
  watch: {
    data: {
      deep: true,
      handler (val) {
        this.updateData(val.list)
      }
    },
    value: {
      deep: true,
      handler (val) {
        this.setValue(val)
      }
    }
  },
  created () {
    this.updateData(this.data.list)
  },
  methods: {
    updateData (list) {
      let newList = []
      list.forEach(item => {
        if (item.columns) {
          item.columns.forEach(val => {
            newList.push(...val.list)
          })
        } else {
          newList.push(item)
        }
      })
      this.models = newList
    },
    setValue (value) {
      let keys =  Object.keys(value)
      this.models.forEach((item, index) => {
        if (value.length < 1 || value[keys.find(val => val == item.model)] == '') {
          this.valueList.push(item.options.defaultValue)
        } else {
          this.valueList.push(value[keys.find(val => val == item.model)])
        }
      })
    },
    submit (val) {
    },
    getData () {
      return new Promise((resolve, reject) => {
        this.$refs.vanForm.validate().then(res => {
          let obj = {}
          this.$refs.fieldListItem.forEach(item => {
            let itemValue = item.getValue()
            obj[itemValue.key] = itemValue.value
          })
          resolve(obj)
        }).catch(err => {
          reject(new Error(this.$t('fm.message.validError')).message)
        })
      })
    }
  }
}
</script>

<style>

</style>